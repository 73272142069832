<template>
  <div>
    <SideNav />
    <div class="flex flex-col w-full px-5 xl:px-20 prod-area pb-32">

      <div class="flex flex-col top-2-area w-full mx-auto ais">
        <div class="flex w-full py-5 border-b relative mt-4 lg:mt-16">
          <div class="flex aic w-full">
            <div class="flex flex-col ais jcb top-2-text">
              <h2 class="nunitoSans-bold w-full text-gray-800 capitalize">Unterbrechen</h2>
              <p class="text-gray-500 w-full fs-12"></p>
            </div>
            <div class="absolute bottom-0 right-0 w-90p h-5p bg-primary"></div>
          </div>
        </div>

        <button
          class="btn-primary w-full md:max-w-max px-8 h-50p rounded-lg shadow-lg fcc text-white mt-7 mb-16"
          type="button"
          @click="openDateModal(null,'new')"
        >
          Neue Pausenzeiten erstellen
        </button>

      </div>

      <div class="flex flex-col w-full">
        <h2 class="nunitoSans-bold w-full text-gray-800 capitalize mb-4">Ihr bricht ein</h2>
        <div class="flex aic jcb w-full bg-secondary text-white px-5">
          <div class="flex aic jcs w-10/12 ml-auto text-xs sm:text-sm h-40p">
            <span class="flex aic w-5/12 h-40p">Start Date</span>
            <span class="flex aic w-5/12 h-40p">End Date</span>
          </div>
          <div class="w-2/12 text-right text-xs md:text-sm">
            Betrieb
          </div>
        </div>
        <div
          class="flex flex-col w-full"
          v-for="breakItem in breakList"
          :key="breakItem.index"
        >
          <div class="flex aic jcb w-full bg-white px-5 border-b border-gray-100">
            <div class="flex aic jcs w-10/12 ml-auto text-xs sm:text-sm h-45p">
              <span class="flex aic w-5/12 h-45p">{{breakItem.start_break_date}}</span>
              <span class="flex aic w-5/12 h-45p">{{breakItem.end_break_date}}</span>
            </div>
            <div class="w-2/12 flex aic jce space-x-4">
              <button
                type="button"
                @click="openDateModal(breakItem,'update') "
                class="betrieb rounded-lg text-green-600 fcc"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 lg:h-5 lg:w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
              </button>
              <button
                class="betrieb rounded-lg text-red-600 fcc"
                type="button"
                @click="deleteBreak(breakItem.id)"
              >
                <svg
                  class="h-3 w-3"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                >
                  <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <DateModal
        :showDateModal="showDateModal"
        @close="showDateModal=false"
        :data="selectedBreak"
        :type="type"
      >
      </DateModal>

    </div>
  </div>
</template>
<script>
import DateModal from "@/components/DateModal.vue";
import SideNav from "@/components/SideNav.vue";
export default {
  components: { SideNav, DateModal },
  data() {
    return {
      showDateModal: false,
      selectedBreak: null,
      type: null,
    };
  },
  computed: {
    breakList() {
      return this.$store.state.subscription.breakList;
    },
  },
  methods: {
    openDateModal(breakItem, type) {
      this.selectedBreak = breakItem;
      this.showDateModal = true;
      this.type = type;
    },
    deleteBreak(break_pattern_id) {
      this.$store.dispatch("subscription/deleteBreak", break_pattern_id);
    },
  },
  mounted() {
    this.$store.dispatch("subscription/getBreak");
  },
};
</script>
