<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="create-in bg-gray-50 z-30 overflow-x-hidden rounded-lg shadow-2xl p-10"
        v-if="showDateModal"
      >

        <div class="flex flex-col ais jcb w-full">
          <div class="flex space-x-8 py-1 mt-10 mb-10 aic w-full">
            <div class="w-full calendar">
              <label
                for="date"
                class="block text-gray-800 text-sm mb-3"
              >{{ $t("Wählen Sie das Startdatum aus") }}</label>
              <div class="mt-1 relative rounded-md pointer">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer pointer-events-none text-xl text-gray-600 h-50p rounded-xl px-5 fcc">
                    <i class="fa fa-calendar"></i>
                </div>
                <datetime
                  v-model="start_break_date"
                  input-class="focus:ring-indigo-500 focus:border-indigo-500 w-full pr-10 text-sm border-gray-300 rounded-md p-3 h-50p shadow-md"
                  :placeholder="$t('Select Date')"
                  format="dd.MM.yy"
                  aria-label="Date Picker"
                  :min-datetime="minDatetime()"
                  required
                >
                </datetime>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col ais jcb w-full">
          <div class="flex space-x-8 py-1 mb-10 aic w-full">
            <div class="w-full calendar">
              <label
                for="date"
                class="block text-gray-800 text-sm mb-3"
              >{{ $t("Wählen Sie das Enddatum aus") }}</label>
              <div class="mt-1 relative rounded-md pointer">
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer pointer-events-none text-xl text-gray-600 h-50p rounded-xl px-5 fcc">
                    <i class="fa fa-calendar"></i>
                </div>
                <datetime
                  v-model="end_break_date"
                  input-class="focus:ring-indigo-500 focus:border-indigo-500 w-full pr-10 text-sm border-gray-300 rounded-md p-3 h-50p shadow-md"
                  :placeholder="$t('Select Date')"
                  format="dd.MM.yy"
                  aria-label="Date Picker"
                  :min-datetime="minDatetime()"
                  required
                >
                </datetime>
              </div>
            </div>
          </div>
        </div>
        <button
          class="btn-secondary w-full md:max-w-max px-8 h-50p rounded-lg shadow-lg fcc text-white mt-0 ml-auto"
          type="button"
          @click="createBreak"
        >
          Erstellen
        </button>
      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="cart-overlay"
        v-if="showDateModal"
        @click="$emit('close')"
      ></div>
    </transition>
  </div>
</template>
<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";

export default {
  props: ["showDateModal", "data", "type"],
  components: { Datetime },
  data() {
    return {
      end_break_date: "",
      start_break_date: "",
    };
  },
  methods: {
    minDatetime() {
      return new Date().toISOString();
    },
    UTCdate(date) {
      return date.split("T")[0];
    },

    createBreak() {
      if (this.type == "new") {
        this.$store
          .dispatch("subscription/createBreak", {
            start_break_date: this.UTCdate(this.start_break_date),
            end_break_date: this.UTCdate(this.end_break_date),
          })
          .then((res) => {
            this.$store.commit("setshowNotif", {
              show: true,
              msg: res.type == "error" ? res.msg : "successfully added.",
              type: res.type,
            });
            if (res.type == "success") this.$emit("close");
          });
      } else {
        this.$store
          .dispatch("subscription/updateBreak", {
            start_break_date: this.UTCdate(this.start_break_date),
            end_break_date: this.UTCdate(this.end_break_date),
            break_pattern_id: this.data.id,
          })
          .then((res) => {
            this.$store.commit("setshowNotif", {
              show: true,
              msg: res.type == "error" ? res.msg : "successfully added.",
              type: res.type,
            });
            if (res.type == "success") this.$emit("close");
          });
      }
    },
  },
  watch: {
    type(val) {
      if (val == "update") {
        this.end_break_date = new Date(this.data.end_break_date).toISOString();
        this.start_break_date = new Date(
          this.data.start_break_date
        ).toISOString();
      } else {
        this.end_break_date = null;
        this.start_break_date = null;
      }
    },
  },
  mounted() {
    Settings.defaultLocale = this.$i18n.locale;
  },
};
</script>
